import { SimpleForm, TextInput } from 'react-admin'
import { useMutation } from '@tanstack/react-query'
import TextField from '@mui/material/TextField'
import { DaredropApi } from '../../utils/daredrop-api'

type CreateShortLinkParams = {
    url: string
    alias: string
}

export const CreateShortLink = () => {
    const createShortLinkMutation = useMutation({
        mutationKey: ['createShortLink'],
        mutationFn: async (params: CreateShortLinkParams) => {
            return DaredropApi.createShortLink(params)
        },
    })

    return (
        <SimpleForm
            onSubmit={async (params) => {
                await createShortLinkMutation.mutateAsync(
                    params as CreateShortLinkParams
                )
            }}
        >
            <p>Create a new short link</p>
            <TextInput
                sx={{ width: 400 }}
                label="URL to shorten"
                source="url"
                required
                helperText="URL to shorten"
            />
            <TextInput
                sx={{ width: 400 }}
                label="Alias"
                source="alias"
                helperText="Optional. Can be used to determine last fragment of url. If not provided, a random alias will be generated."
            />
            {createShortLinkMutation.isLoading && <p>Loading...</p>}
            {createShortLinkMutation.data && (
                <>
                    <p>Short link created!</p>
                    <TextField
                        label="Short link"
                        color="success"
                        sx={{ width: 400 }}
                        InputProps={{
                            readOnly: true,
                        }}
                        value={`https://${createShortLinkMutation.data.shortUrl}`}
                    />
                </>
            )}
        </SimpleForm>
    )
}
