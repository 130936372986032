import { SimpleForm, TextInput } from 'react-admin'
import { useMutation } from '@tanstack/react-query'
import { Check, Close } from '@material-ui/icons'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import { DaredropApi } from '../../utils/daredrop-api'

export const ShortLinkStats = () => {
    const getShortLinkStatsMutation = useMutation({
        mutationKey: ['getShortLinkStats'],
        mutationFn: async (shortLink: string) => {
            return DaredropApi.getShortLinkStats(shortLink)
        },
    })

    return (
        <SimpleForm
            onSubmit={(data) => {
                getShortLinkStatsMutation.mutate(data.shortUrl)
            }}
        >
            <p>Get short link stats</p>
            <TextInput
                sx={{ width: 400 }}
                required
                label="Short url"
                source="shortUrl"
                helperText="Returns stats for a short link. If the short link is not found, an error will be returned. Statistics are updated every 30 minutes."
            />
            {getShortLinkStatsMutation.isLoading && <p>Loading...</p>}
            {getShortLinkStatsMutation.data && (
                <Table>
                    <TableRow>
                        <TableCell>Short link</TableCell>
                        <TableCell>
                            <a
                                target="_blank"
                                href={getShortLinkStatsMutation.data.shortUrl}
                                rel="noreferrer"
                            >
                                {getShortLinkStatsMutation.data.shortUrl}
                            </a>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Clicks</TableCell>
                        <TableCell>
                            {new Intl.NumberFormat().format(
                                getShortLinkStatsMutation.data.clicks
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Alias</TableCell>
                        <TableCell>
                            {getShortLinkStatsMutation.data.alias}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Legacy</TableCell>
                        <TableCell>
                            {getShortLinkStatsMutation.data.isLegacy ? (
                                <Check />
                            ) : (
                                <Close />
                            )}
                        </TableCell>
                    </TableRow>
                </Table>
            )}
        </SimpleForm>
    )
}
