import { resourcesIds } from '../resources'
import {
    editListItemsIdKey,
    editOneItemIdKey,
    getListKey,
    getOneKey,
    updateKey,
} from '../keys'
import { objectDiff } from '../utils/util'

export type CampaignTerms = {
    id: string
    created: string
    creatorId: string
    dropId: string
    estimatedPointsToEarn: number
    reservedPointsLeft: number
    reservedPointsTotal: number
    pointsReservationDueDate?: string
    twitchName?: string
}

type UpdateCampaignTermsParams = {
    data: CampaignTerms
    previousData: CampaignTerms
}

export const campaignTerms = {
    [resourcesIds.CAMPAIGN_TERMS]: {
        [getListKey]: ({ urlPath }: { urlPath: string[] }) => {
            const dropId = urlPath[2]

            if (!dropId) {
                return undefined
            }

            return {
                endpointId: 'LIST_CAMPAIGN_TERMS_ACCEPTED',
                payload: { dropId },
            }
        },
        [getOneKey]: ({ urlPath }: { urlPath: string[] }) => {
            const [, , dropId, creatorId] = urlPath

            if (!dropId || !creatorId) {
                return undefined
            }

            return {
                endpointId: 'GET_CAMPAIGN_TERMS_ACCEPTED',
                payload: { dropId, creatorId },
            }
        },
        [updateKey]: (params: UpdateCampaignTermsParams) => {
            const { data, previousData } = params
            const { dropId, creatorId } = data
            const update = objectDiff(previousData, data)
            return {
                endpointId: 'UPDATE_CAMPAIGN_TERMS_ACCEPTED',
                payload: {
                    dropId,
                    creatorId,
                    update,
                },
            }
        },
        [editListItemsIdKey]: (rawData: Record<string, any>[]) => {
            return rawData.map((data) => ({
                id: `${data.dropId}${data.creatorId}`,
                ...data,
            }))
        },
        [editOneItemIdKey]: (rawData: Record<string, any>) => {
            return rawData
        },
    },
}
