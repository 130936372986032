import { resourcesIds } from 'resources'
import { createKey, getOneKey } from 'keys'

export type CreateShortLinkPayload = {
    url: string
    alias?: string
}

export const shortLinks = {
    [resourcesIds.SHORT_LINKS]: {
        [createKey]: (data: CreateShortLinkPayload) => {
            return {
                endpointId: 'CREATE_SHORT_LINK',
                payload: {
                    url: data.url,
                    alias: data.alias,
                },
            }
        },
        [getOneKey]: ({ id }: { id: string }) => {
            return {
                endpointId: 'GET_SHORT_LINK',
                payload: {
                    id,
                },
            }
        },
    },
}
