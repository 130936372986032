import { resourcesIds } from 'resources'
import {
    editListItemsIdKey,
    editOneItemIdKey,
    getListKey,
    updateKey,
} from 'keys'
import { getCurrentQueryValue } from '../utils/common'
import { Platform } from '../types'

// TODO add type PointsApplication

export enum PointsApplicationState {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
}

export type PointsApplication = {
    dropId: string
    brandId: string
    platform: Platform
    platformId: string
    comment?: string
    reservedPoints: number
    state: PointsApplicationState
    language?: string
    averageViewers?: number
    mnemonicId?: string
    uniqueDropsNumber?: number
    lastDropParticipationDate?: string
    sullygnomeLink?: string
    channelUrl?: string
    clickStats: {
        totalLinks: number
        totalClicks: number
        averageClicks: number
    }
}

export const pointsApplicationList = {
    [resourcesIds.POINTS_APPLICATION_LIST]: {
        [getListKey]: (data: {
            pagination: { perPage: number; page: number }
            filter: { state?: PointsApplicationState }
        }) => {
            const dropId = getCurrentQueryValue()

            if (!dropId) {
                throw new Error('No drop id.')
            }

            return {
                endpointId: 'GET_POINTS_APPLICATIONS',
                payload: {
                    dropId,
                    page: data.pagination.page,
                    limit: data.pagination.perPage,
                    states: [data.filter.state],
                },
                pagination: true,
            }
        },
        [editListItemsIdKey]: (
            rawData: PointsApplication[]
        ): (PointsApplication & { id: string })[] =>
            rawData.map((item) => ({
                ...item,
                id: `${item.platform}|${item.platformId}`,
            })),
        [editOneItemIdKey]: (
            data: PointsApplication
        ): PointsApplication & { id: string } => ({
            ...data,
            id: `${data.platform}|${data.platformId}`,
        }),
        [updateKey]: ({
            data,
        }: {
            data: {
                platform: Platform
                platformId: string
                dropId: string
                state: PointsApplicationState
            }
        }) => {
            return {
                endpointId: 'SET_POINTS_APPLICATION_STATE',
                payload: data,
            }
        },
    },
}
