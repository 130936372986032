import Paper from '@mui/material/Paper'
import { ShortLinkStats } from './ShortLinkStats'
import { CreateShortLink } from './CreateShortLink'

export const ShortLinks = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            <Paper>
                <CreateShortLink />
            </Paper>
            <Paper>
                <ShortLinkStats />
            </Paper>
        </div>
    )
}
