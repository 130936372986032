import { MenuItemLink } from 'react-admin'
import {
    Book,
    Person,
    Group,
    // Games,
    Storefront,
    ListAlt,
    AccountBalance,
    BarChart,
    VpnKey,
    InsertChart,
    YouTube,
    ListSharp,
    MusicNote,
    Tv,
    Link,
} from '@material-ui/icons'
import { FunctionComponent, CSSProperties } from 'react'
import { resourcesIds } from 'resources'

const menuItems = [
    {
        name: 'creators-report-alt',
        text: 'Creators Report Twitch',
        icon: <Tv />,
    },
    {
        name: resourcesIds.CREATORS_REPORT_YOUTUBE,
        text: 'Creators Report YouTube',
        icon: <YouTube />,
    },
    {
        name: resourcesIds.CREATORS_REPORT_YOUTUBE_V2,
        text: 'Creators Report YouTube (V2)',
        icon: <YouTube />,
    },
    {
        name: 'creators-report-tiktok',
        text: 'Creators Report Tiktok',
        icon: <MusicNote />,
    },
    {
        name: resourcesIds.STREAMS_STATS_REPORT,
        text: 'Streams stats report',
        icon: <Book />,
    },
    // {
    //     name: resourcesIds.GAME_STATS_REPORT,
    //     text: 'Game stats report',
    //     icon: <Games />,
    // },
    {
        name: resourcesIds.CAMPAIGNS_RESOURCE_ID,
        text: 'Campaigns',
        icon: <ListAlt />,
    },
    {
        name: resourcesIds.PREVIOUS_DARES_RESOURCE_ID,
        text: 'Previous Dares',
        icon: <ListAlt />,
    },
    {
        name: resourcesIds.SIGNED_UP_CREATORS_RESOURCE_ID,
        text: 'Signed Up Creators',
        icon: <Person />,
    },
    {
        name: resourcesIds.USERS_LIST_RESOURCE_ID,
        text: 'Users list',
        icon: <Group />,
    },
    {
        name: resourcesIds.POINTS_MARKETPLACE_RESOURCE_ID,
        text: 'Points Marketplace',
        icon: <Storefront />,
    },
    {
        name: resourcesIds.POINTS_TRANSACTIONS_RESOURCE_ID,
        text: 'Points transactions',
        icon: <AccountBalance />,
    },
    {
        name: resourcesIds.POINTS_STATISTICS,
        text: 'Points statistics',
        icon: <BarChart />,
    },
    {
        name: resourcesIds.REWARDS_RESOURCE_ID,
        text: 'Rewards',
        icon: <VpnKey />,
    },
    {
        name: resourcesIds.STACKS_METRICS,
        text: 'Stacks Metrics',
        icon: <InsertChart />,
    },
    {
        name: resourcesIds.VERIFICATIONS,
        text: 'Verifications',
        icon: <ListSharp />,
    },
    {
        name: resourcesIds.SHORT_LINKS,
        text: 'Short Links',
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        icon: <Link />,
    },
]

const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 4,
    marginTop: 20,
} as CSSProperties

const Menu: FunctionComponent = () => (
    <div style={style}>
        {menuItems.map(({ name, text, icon: Icon }) => (
            <MenuItemLink
                tooltipProps={{
                    disableInteractive: true,
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    children: <>{text}</>,
                    title: text,
                    placement: 'bottom-start',
                }}
                key={name}
                to={`/${name}`}
                primaryText={text}
                leftIcon={Icon}
                sx={{
                    marginBottom: 1,
                }}
            />
        ))}
    </div>
)

export default Menu
